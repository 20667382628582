<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div @click="!submitting ? close() : 0" class="icon">
                <ModalCloseIcon :dark="true" />
              </div>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <span class="title">ADD CARD</span>
              <div class="inputs">
                <input
                  type="text"
                  class="name-input"
                  placeholder="Full Name"
                  v-model="name"
                />
                <div class="loading" v-if="!isReady">Loading...</div>
                <div class="card-input" :class="{ focused }" v-show="isReady">
                  <div ref="card" />
                </div>
                <div id="card-errors" />
              </div>
              <div class="buttons">
                <button
                  class="primary outline dark"
                  :disabled="submitting"
                  @click="close"
                >
                  Cancel
                </button>
                <button
                  class="primary"
                  :disabled="!name || !validCard || submitting"
                  @click="submit"
                >
                  {{ submitting ? "Submitting..." : "Add" }}
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
/* eslint-disable no-undef */
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";

import ADD_PAYMENT_METHOD from "@/api/mutations/ADD_PAYMENT_METHOD.gql";
import GET_PAYMENT_METHODS from "@/api/queries/GET_PAYMENT_METHODS.gql";
import ADD_EXTERNAL_ACCOUNT from "@/api/mutations/ADD_EXTERNAL_ACCOUNT.gql";
import GET_EXTERNAL_ACCOUNTS from "@/api/queries/GET_EXTERNAL_ACCOUNTS.gql";

let stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
let elements = stripe.elements();
let card = undefined;

export default {
  name: "AddCard",
  components: {
    ModalCloseIcon
  },
  props: {
    isOnboarding: Boolean,
    isPayouts: Boolean,
    chargesEnabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      submitting: false,
      validCard: false,
      focused: false,
      isReady: false,
      name: ""
    };
  },
  mounted() {
    card = elements.create("card", {
      style: {
        base: {
          fontSize: "14px",
          lineHeight: "14px",
          fontFamily: "sofia-pro, sans-serif"
        }
      }
    });
    card.mount(this.$refs.card);
    card.on("change", e => {
      this.validCard = e.complete;
      var displayError = document.getElementById("card-errors");
      if (e.error) {
        displayError.textContent = e.error.message;
      } else {
        displayError.textContent = "";
      }
    });
    card.on("ready", () => {
      this.isReady = true;
    });
    card.on("focus", () => {
      this.focused = true;
    });
    card.on("blur", () => {
      this.focused = false;
    });
  },
  methods: {
    close() {
      if (this.submitting) return;
      card.destroy();
      this.$emit("close");
    },
    async getToken(extraDetails) {
      return stripe
        .createToken(card, extraDetails)
        .then(async ({ token: { id, card: added, type } }) => {
          if (type !== "card") {
            alert("Error Adding Card");
            this.submitting = false;
            card.clear();
            throw new Error("Error Adding Card");
          }

          return { id, added };
        });
    },
    async addCard({ id }) {
      await this.$apollo.mutate({
        mutation: ADD_PAYMENT_METHOD,
        variables: { token: id },
        update: async (store, { data: { stripe_add_payment_method } }) => {
          if (!this.isOnboarding) {
            const data = store.readQuery({ query: GET_PAYMENT_METHODS });
            data.stripe_get_payment_methods = [
              ...data.stripe_get_payment_methods,
              stripe_add_payment_method
            ];
            await store.writeQuery({ query: GET_PAYMENT_METHODS, data });
          }

          this.submitting = false;
          card.destroy();

          this.$emit("close", stripe_add_payment_method);
        }
      });
    },
    async addExternalAccount({ id, added }) {
      if (added.funding !== "debit") {
        alert("Only Debit cards allowed");
        this.submitting = false;
        return card.clear();
      }

      await this.$apollo.mutate({
        mutation: ADD_EXTERNAL_ACCOUNT,
        variables: { token: id },
        update: async (store, { data: { stripe_add_external_account } }) => {
          const data = store.readQuery({
            query: GET_EXTERNAL_ACCOUNTS
          });

          data.stripe_get_external_accounts = [
            ...data.stripe_get_external_accounts,
            stripe_add_external_account
          ];

          return store.writeQuery({
            query: GET_EXTERNAL_ACCOUNTS,
            data
          });
        }
      });
    },
    async submit() {
      try {
        this.submitting = true;
        const extraDetails = {
          name: this.name,
          currency: "usd"
        };

        let token = await this.getToken(extraDetails);

        if (
          this.chargesEnabled &&
          (this.isPayouts || token.added.funding === "debit")
        ) {
          await this.addExternalAccount(token);
          if (!this.isPayouts) token = await this.getToken(extraDetails);
          else {
            this.submitting = false;
            card.destroy();
            return this.$emit("close");
          }
        }

        return this.addCard(token);
      } catch (err) {
        if (err.message) alert(err.message);
        else console.log(err);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/_mixins.scss";

.modal-container {
  max-width: 600px;
  height: fit-content;
  margin: 0 5rem;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  padding: 32px 32px 0;
}

.modal-body {
  padding: 0 34px 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .title {
    margin-bottom: 30px;
    font-size: 1.5rem;
    color: $white;
  }
  .inputs {
    min-width: 20rem;
    .loading,
    .name-input {
      color: #555;
      font-size: 0.9rem;
      height: 42px;
      line-height: 12px;
      font-size: 14px;
      margin-bottom: 8px;
      background-color: #eee;
      padding: 13px 23px;
      border-radius: 38px;
      border: 1px #eee solid;
      width: 100%;
      &:focus {
        outline: none;
      }
    }
    .card-input {
      margin-bottom: 8px;
      background-color: #eee;
      padding: 13px;
      border-radius: 38px;
      border: 1px #eee solid;
    }
    .focused {
      border: 1px $accent solid;
    }
    #card-errors {
      color: $accent;
      font-size: 0.9rem;
    }
  }
  .buttons {
    button {
      margin: 30px 0.5rem 0;
      width: 136px;
      height: 44px;
    }
  }
}
</style>
